import type { NextPage } from "next";
import { authStore } from "@state/store";

import Authentication from "./authentication";
import Meetings from "./meetings";

const HomePage: NextPage = () => {
  const { isSignedIn } = authStore();

  return <>{isSignedIn ? <Meetings /> : <Authentication />}</>;
};

export default HomePage;
